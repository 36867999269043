import request from '../utils/request';
//核对订单信息
export function toOrderConfirm(data) {
  return request({
    url: '/order/toOrderConfirm.do',
    method: 'post',
    data
  });
}

//生成订单
export function toGenerateOrder(data) {
  return request({
    url: '/order/toGenerateOrder.do',
    method: 'post',
    data
  });
}

//查询支付订单
export function toPayOrder(data) {
  return request({
    url: '/order/toPayOrder.do',
    method: 'post',
    data
  });
}

//发送发票信息
export function doGenerateInvoiceNew(data) {
  return request({
    url: '/userData/doGenerateInvoiceNew.do',
    method: 'post',
    data
  });
}

//支付订单
export function payEnd(data) {
  return request({
    url: '/order/payEnd.do',
    method: 'post',
    data
  });
}


//学习卡支付
export function toPayOrderByCard(data) {
  return request({
    url: '/order/toPayOrderByCard.do',
    method: 'post',
    data
  });
}

//支付宝对账
export function toTradeQueryZFB(data) {
  return request({
    url: '/order/toTradeQueryZFB.do',
    method: 'post',
    data
  });
}

//支付宝对账
export function toTradeQueryZFB2(data) {
  return request({
    url: '/order/toTradeQueryZFB2.do',
    method: 'post',
    data
  });
}

//wx对账
export function toTradeQueryWX(data) {
  return request({
    url: '/order/toTradeQueryWX.do',
    method: 'post',
    data
  });
}

//wx对账
export function toTradeQueryWX2(data) {
  return request({
    url: '/order/toTradeQueryWX2.do',
    method: 'post',
    data
  });
}

//支付成功页面
export function toPaySuccess(data) {
  return request({
    url: '/order/toPaySuccess.do',
    method: 'post',
    data
  });
}

//运单支付
export function payEnd3(data) {
  return request({
    url: '/order/payEnd3.do',
    method: 'post',
    data
  });
}

//运单查询
export function getExpressDetail(data) {
  return request({
    url: '/userData/getExpressDetail.do',
    method: 'post',
    data
  });
}

//订单查询
export function getOrderPayInfo(data) {
  return request({
    url: '/order/getOrderPayInfo.do',
    method: 'post',
    data
  });
}

