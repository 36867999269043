<template>
  <div>
    <pcHeaders></pcHeaders>
    <div class="namms">
      <div>
        <div class="suc">
          <div><img src="../../assets/images/pay_03.png"
                 alt=""></div>
          <div style="font-size: 22px;color: #ff9f53;margin-top: 22px;">恭喜您，支付成功</div>
        </div>

        <div class="payDetail">
          <div class="payHead">
            <div class="su"></div>
            <div style="font-weight: bold;">支付详情</div>
          </div>
          <div class="list">
            <div>订单编号</div>
            <div>{{ orderList.ORD_NO }}</div>
          </div>
          <div class="list">
            <div>订单金额</div>
            <div style="color: #ff9f53;">¥{{ orderList.ORD_TOTAL_MONEY }}</div>
          </div>
          <div class="list">
            <div>支付时间</div>
            <div>{{ orderList.ORD_PAYTIME }}</div>
          </div>
          <div class="list">
            <div>支付方式</div>
            <div>
              {{ orderList.ORD_PAYTYPE =='01' ? '在线支付' :  orderList.ORD_PAYTYPE =='02' ? '学习帐户' :orderList.ORD_PAYTYPE =='03' ? '现金面付' :orderList.ORD_PAYTYPE =='04' ? '银行汇款' :orderList.ORD_PAYTYPE =='05' ? '邮局汇款' :orderList.ORD_PAYTYPE =='06' ? '支付宝'  :orderList.ORD_PAYTYPE =='07' ? '手机支付(支付宝)' :orderList.ORD_PAYTYPE =='08' ? '刷卡支付' :orderList.ORD_PAYTYPE =='00' ? '其它' :orderList.ORD_PAYTYPE =='0901' ? '微信公众号' :orderList.ORD_PAYTYPE =='0902' ? '微信WAP' :orderList.ORD_PAYTYPE =='0903' ? '微信扫码' :orderList.ORD_PAYTYPE =='0904' ? '微信APP' :orderList.ORD_PAYTYPE =='10' ? '手工' :orderList.ORD_PAYTYPE =='11' ? '银联支付' :orderList.ORD_PAYTYPE =='12' ? '手机银联' :orderList.ORD_PAYTYPE =='13' ? '未到账' :''}}
            </div>
          </div>
          <div class="list">
            <div>支付金额</div>
            <div style="color: #ff9f53;">￥{{ orderList.ORD_MONEYSUM }}</div>
          </div>
        </div>

        <div class="payDetail" v-if="isMember !== '02'">
          <div class="payHead"
               style="border-bottom: 1px dashed #d0d0d0;">
            <div class="su"></div>
            <div style="font-weight: bold;">课程详情</div>
          </div>

          <div class="tables">
            <div style="width: 35%;">班级名称</div>
            <div style="width: 35%;">课程/考试</div>
            <div style="width: 10%;">学时</div>
            <div style="border-right: 0;width: 15%;">学习课程</div>
          </div>
          <div class="tables"
               v-for="(item,idx) in eOrderDetailList"
               :key="idx">
            <div style="width: 35%;">{{ item.trainClass.CLASS_NAME }}</div>
            <div style="width: 35%;">{{ item.resCourse.C_NAME }}</div>
            <div style="width: 10%;">{{ item.resCourse.C_CREDITHOUR }}</div>
            <div style="border-right: 0;width: 15%;">
              <div style="width: 100% !important;border: 0 !important;"
                   v-if="item.trainClass.CLASS_SOURCE_CODE_NO =='2302' || item.trainClass.CLASS_SOURCE_CODE_NO =='2303'">
                <span v-if="item.trainClass.CLASS_SOURCE_CODE_NO =='2302'"
                      class="sudyBtn" @click="goStudy">开始学习</span>
                <span v-else
                      class="sudyBtn">学课考试</span>
              </div>
              <span v-else>
                <span style="width: 100% !important;border: 0 !important;"
                      v-if="item.IS_COURSE =='02'"
                      class="sudyBtn" @click="goStudy">开始学习</span>
              </span>
            </div>
          </div>
          <div class="tables"
               v-for="(item) in independentExamList"
               :key="item.trainClass.ID">
            <div style="width: 35%;">{{ item.trainClass.CLASS_NAME }}</div>
            <div style="width: 35%;">{{ item.EXAM_QUIZ.NAME }}</div>
            <div style="width: 10%;">{{ item.EXAM_QUIZ.CREDIT }}</div>
            <div style="border-right: 0;width: 15%;">
              <div style="width: 100% !important;border: 0 !important;"
                   v-if="item.trainClass.CLASS_SOURCE_NO =='2302' || item.trainClass.CLASS_SOURCE_NO =='2303'">
                <div v-if="item.trainClass.CLASS_SOURCE_CODE_NO =='2302'">
                  <div v-if="item.IS_EXAM == '02'"
                       class="sudyBtn" @click="goStudy">参加考试</div>
                  <span v-else
                        class="sudyBtn" @click="goStudy">学课考试</span>
                </div>

              </div>
              <div v-else
                   style="width: 100% !important;border: 0 !important;">
                <div v-if="item.IS_EXAM =='02'"
                     class="sudyBtn" @click="goStudy">参加考试</div>
              </div>
            </div>
          </div>
        </div> 
        <div v-else @click="goZy" style="margin-top: 15px;">
          <div class="flex resources">
          <div class="leftTop">课程资源库会员权益</div>
          <img style="width: 1200px; height: 120px; position: absolute; top: 0; left: 0; z-index: -1"
               src="../../assets/images/resources.png"
               alt="" />
          <div class="flex"
               style="justify-content: space-between; width: 100%">
            <div style="color: #fff">
              <div style="font-size: 22px; font-weight: bold">恭喜你！！！已开通课程资源库会员特权</div>
              <div style="margin-top: 8px; font-size: 14px; opacity: 0.7">
                ·开通会员365天内享受特权&emsp;·课程资源可不限量免费观看&emsp;·如有其他疑问请联系客服400-8816-822
              </div>
            </div>
            <div class="hyBtn" @click="goZy">前往课程资源库</div>
          </div>
        </div>
        </div>
      </div>
    </div>
    <pcBottom></pcBottom>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import pcHeaders from '../component/head.vue';
import pcBottom from '../component/bottom.vue';
import { toPaySuccess } from '@/api/pay.js';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    pcHeaders,
    pcBottom
  },
  data() {
    // 这里存放数据
    return {
      orderList: {},
      independentExamList: [],
      eOrderDetailList: [],
      isMember:'',
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    goZy() {
      this.$router.push('/resourcesList')
    },
    goStudy() {
      this.$router.push('/studyMain')
    },
    getList() {
      toPaySuccess({ orderId: this.$route.query.orderId }).then(res => {
        console.log(res.data.data);
        this.isMember = res.data.data.isMember
        this.orderList = res.data.data.orderList;
        this.independentExamList = res.data.data.independentExamList;
        this.eOrderDetailList = res.data.data.eOrderDetailList;
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getList();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.flex {
  display: flex;
}
.resources {
      position: relative;
      width: 1200px;
      height: 120px;
      padding: 30px 40px 30px 134px;
      cursor: pointer;
      .leftTop {
        position: absolute;
        top: 0;
        left: 0;
        width: 134px;
        padding-left: 9px;
        height: 22px;
        line-height: 22px;
        color: #fff;
        z-index: 2;
        background: url('../../assets/images/topleft.png');
        font-size: 11px;
      }
      .hyBtn {
        width: 110px;
        height: 36px;
        background: linear-gradient(90deg, #fdf8ef 0%, #f9dea2 100%);
        cursor: pointer;
        text-align: center;
        line-height: 36px;
        font-size: 14px;
        color: #957b5f;
        margin-top: 12px;
      }
    }
.namms {
  display: flex;
  justify-content: center;
  padding: 30px;

  .suc {
    width: 1200px;
    height: 198px;
    border: 1px solid #ebebeb;
    box-shadow: 0 0 6px #ccc;
    margin-top: 22px;
    text-align: center;
    padding-top: 45px;
  }

  .payDetail {
    width: 1200px;
    margin-top: 16px;
    .payHead {
      padding: 0 45px;
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #666;
      height: 54px;
      border-top: 1px solid #d0d0d0;
      border-left: 1px solid #d0d0d0;
      border-right: 1px solid #d0d0d0;
      .su {
        width: 5px;
        height: 22px;
        border-radius: 4px;
        background: #ffa45b;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    .list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px dashed #d0d0d0;
      height: 40px;
      background: #f7f7f7;
      padding: 0 45px;
      font-size: 14px;
      color: #999999;
    }
    .tables {
      width: 100%;
      display: flex;
      justify-content: space-around;
      font-size: 16px;
      border: 1px solid #d0d0d0;
      border-top: 0;
      min-height: 60px;

      div {
        border-right: 1px solid #d0d0d0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25%;
      }
      div:nth-child(5) {
        border: 0 !important;
      }
      .sudyBtn {
        width: 50% !important;
        height: 25px;
        color: #ff9f53;
        border-radius: 3px;
        line-height: 25px;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
        border: 0 !important;
      }
    }
  }
}
</style>